<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入留言内容关键字"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-select v-decorator="['status']" class="sm-select" placeholder="状态">
          <a-select-option value="0">待处理</a-select-option>
          <a-select-option value="1"> 已采用 </a-select-option>
          <a-select-option value="2"> 已回复 </a-select-option>
          <a-select-option value="3"> 无效</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">
          刷新
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: 1600 }"
    >
      <template slot="user_type" slot-scope="text, record">
        <a href="javascript:void(0)" style="color: #333">
          {{ record.user_id == 0 ? "游客" : "注册用户" }}
        </a>
      </template>
      <template slot="img_name" slot-scope="text, record">
        <a href="javascript:void(0)" @click="handleToAvator(record)">
          {{ text }}
        </a>
      </template>
      <template slot="detail" slot-scope="text, record">
        <a
          href="javascript:void(0)"
          v-if="record.status != '待处理'"
          @click="handleToDetail(record)"
        >
          查看详情
        </a>
      </template>
      <span slot="action" slot-scope="text, record">
        <a
          href="javascript:;"
          v-if="record.status == '待处理'"
          @click="handleAction('unactivity', record)"
          >无效</a
        >
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          v-if="record.status == '待处理'"
          @click="handleAction('receive', record)"
          >采用</a
        >
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          v-if="record.status == '待处理'"
          @click="handleAction('reply', record)"
          >回复</a
        >
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
    <a-modal
      title="提示"
      v-model="visible1"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        v-if="status1 == 'unactivity'"
      >
        <p>确定将状态标记为无效吗？</p>
        <a-form-item label="">
          <p>理由(非必填)：</p>
          <a-textarea
            v-decorator="['reason']"
            placeholder="请输入无效原因"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <a-form
        :form="form2"
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        v-if="status1 == 'receive'"
      >
        <p>确定采用此留言吗？</p>
        <a-form-item label="" v-if="userId != 0">
          <p>奖励积分(非必填)：</p>
          <a-input v-decorator="['point']" placeholder="请输入奖励积分" />
        </a-form-item>
        <a-form-item label="">
          <p>邮箱回复内容(非必填)：</p>
          <a-textarea
            v-decorator="['reply']"
            placeholder="请输入邮箱回复内容"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <a-form
        :form="form2"
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        v-if="status1 == 'reply'"
      >
        <a-form-item label="">
          <p>邮箱回复内容(必填)：</p>
          <a-textarea
            placeholder="请输入邮箱回复内容"
            :rows="8"
            v-decorator="[
              'reply',
              { rules: [{ required: true, message: '邮箱回复内容必填！' }] },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      title="处理详情"
      v-model="visible2"
      :footer="null"
      class="detailModal"
    >
      <p v-if="record.status == '已采用' && record.user_id != 0">
        <span>奖励积分：</span>
        {{ record.point }}
      </p>
      <p v-if="record.status != '无效'">
        <span>邮箱回复内容：</span>
        <!-- 尊敬的用户，您的建议已被我们采用。为了感谢您的支持，我们赠送您一定数量的积分，快登录软猫下载去使用吧。 -->
        {{ record.reply }}
      </p>
      <p v-if="record.status == '无效'">
        <span>处理结果：</span>
        无效
      </p>
      <p v-if="record.status == '无效'">
        <span>原因：</span>
        {{ record.reason }}
      </p>
      <p>
        <span>处理时间：</span>
        {{ record.updated_at }}
      </p>
      <p>
        <span>操作人员：</span>
        {{ record.role }}
      </p>
      <div class="footer">
        <a-button type="primary" @click="handleToClose">关闭</a-button>
      </div>
    </a-modal>
    <a-modal
      title="照片"
      :width="800"
      v-model="visible3"
      :footer="null"
      class="detailModal"
    >
      <div
        style="text-align: center; padding: 10px; height: 500px; overflow: auto"
      >
        <img style="max-width: 700px" :src="imgUrl" alt="" />
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleToClose">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { MessageModel } from "../../models/message";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
import { UsersModel } from "../../models/user";
const usersModel = new UsersModel();
const messageModel = new MessageModel();
const columns = [
  {
    title: "用户姓名",
    dataIndex: "user_name",
    key: "user_name",
    width: 100,
  },
  {
    title: "用户类型",
    dataIndex: "user_type",
    key: "user_type",
    width: 80,
    scopedSlots: { customRender: "user_type" },
  },
  {
    title: "电话",
    dataIndex: "phone_number",
    key: "phone_number",
    width: 110,
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
    width: 200,
  },
  {
    title: "留言内容",
    dataIndex: "content",
    key: "content",
    ellipsis: true,
  },
  {
    title: "照片",
    dataIndex: "img_name",
    key: "img_name",
    width: 250,
    ellipsis: true,
    scopedSlots: { customRender: "img_name" },
  },
  {
    title: "留言时间",
    dataIndex: "created_at",
    key: "created_at",
    width: 170,
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    width: 100,
  },
  {
    title: "处理详情",
    dataIndex: "detail",
    key: "detail",
    width: 100,
    scopedSlots: { customRender: "detail" },
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    width: 150,
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      userData: [],
      columns,
      visible1: false,
      visible2: false,
      visible3: false,
      formLayout: "horizontal",
      form2: this.$form.createForm(this, { name: "coordinated" }),
      flag: "",
      editId: 0,
      total: 0,
      current: 1,
      status: "",
      messageStatus: ["待处理", "已采用", "已回复", "无效"],
      id: 0,
      record: {},
      imgUrl: "",
      userId: 0,
    };
  },

  created() {
    // this.queryAdsList();
    this.queryMessagesList();
    this.queryUserList();
  },

  computed: {
    ...mapGetters(["id"]),
  },

  methods: {
    handleAction(text, record) {
      this.id = record.id;
      this.userId = record.user_id;
      this.visible1 = true;
      this.status1 = text;
      if (text != "unactivity") {
        let reply =
          this.userId == 0
            ? "尊敬的用户，您的建议已被我们采用，感谢您对软猫下载平台的支持。现在注册即送5账户积分，还在等什么，快去注册吧，体验高速优质的下载服务。"
            : "尊敬的用户，您的建议已被我们采用。为了感谢您的支持，我们赠送您一定数量的积分，快登录软猫下载去使用吧。";
        this.$nextTick(() => {
          this.form2.setFieldsValue({
            reply: reply,
          });
        });
      }
    },

    handleToAvator(record) {
      this.visible3 = true;
      this.record = record;
      this.imgUrl = record.img_url;
    },

    handleToDetail(record) {
      this.userData.forEach((item) => {
        if (item.id == record.auditor_id) {
          record.role = item.admin_name;
        }
      });
      this.visible2 = true;
      this.record = record;
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        let status = values.status >= 0 ? Number(values.status) : undefined;
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryMessagesList(key, status, 1);
        }
      });
    },

    handleClear() {
      this.queryMessagesList();
      this.form1.resetFields();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        let status = values.status >= 0 ? Number(values.status) : undefined;
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryMessagesList(key, status, page);
        }
      });
    },

    handleToClose() {
      this.visible2 = false;
      this.visible3 = false;
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          let obj = {};
          if (this.status1 == "receive") {
            if (values.point && !/^[0-9]*[1-9][0-9]*$/.test(values.point)) {
              this.$notification.open({
                message: "提示",
                description: "积分必须为正整数！",
                duration: 2,
              });
              return;
            }
            obj = {
              status: 1,
              reply: values.reply,
              point: values.point ? values.point.trim() : 0,
              user_id: this.userId,
              auditor_id: JSON.parse(localStorage.getItem("vuex")).id,
            };
          } else if (this.status1 == "unactivity") {
            if (values.reason && values.reason.length > 200) {
              this.$notification.open({
                message: "提示",
                description: "无效理由不能超过200字",
                duration: 2,
              });
              return;
            }
            obj = {
              status: 3,
              reason: values.reason,
              auditor_id: JSON.parse(localStorage.getItem("vuex")).id,
            };
          } else {
            obj = {
              status: 2,
              reply: values.reply,
              auditor_id: JSON.parse(localStorage.getItem("vuex")).id,
            };
          }

          console.log(obj, 6666);
          messageModel.update(obj, this.id).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.visible1 = false;
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryMessagesList();
            }
          });
          this.form2.resetFields();
        }
      });
    },

    queryMessagesList(key, status, page) {
      this.loading = true;
      messageModel.index(key, status, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.created_at = formatTime(item.created_at);
            item.updated_at = formatTime(item.updated_at);
            item.status = this.messageStatus[item.status];
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },

    queryUserList(key, page) {
      usersModel.index(key, page).then((res) => {
        if (res.code == 0) {
          this.userData = res.result;
        }
      });
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.detailModal p span {
  font-weight: bold;
}
.detailModal .footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}
</style>
